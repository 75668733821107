import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/lang/en';

Vue.use(VueI18n);

const loadedLanguages = ['en'];

export const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages, // set locale messages
  silentTranslationWarn: true,
});

function setI18nLanguage(lang) {
  i18n.locale = lang;
  return lang;
}

function getMessages(lang) {
  switch (lang) {
    case 'en':
      return import(/* webpackChunkName: "lang-[request]" */ '@/lang/en.js');
    case 'tr':
      return import(/* webpackChunkName: "lang-[request]" */ '@/lang/tr.js');
    case 'es':
      return import(/* webpackChunkName: "lang-[request]" */ '@/lang/es.js');
    case 'de':
      return import(/* webpackChunkName: "lang-[request]" */ '@/lang/de.js');
    case 'fr':
      return import(/* webpackChunkName: "lang-[request]" */ '@/lang/fr.js');
    case 'ro':
      return import(/* webpackChunkName: "lang-[request]" */ '@/lang/ro.js');
    default:
      return import(/* webpackChunkName: "lang-[request]" */ '@/lang/en.js');
  }
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  return getMessages(lang).then((m) => {
    i18n.setLocaleMessage(lang, m.default[lang]);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  });
}
